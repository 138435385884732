// i18next-extract-mark-ns-start products-wave-faspm

import { WAVE_CAPABILITIES_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductWaveSolversPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); const image = getImage(data.image.childImageSharp.gatsbyImageData);

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="narrow"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content_1">
						<p>
							WAVE enables accurate 1D engine digital twins reliant on three aspects:
						</p>

						<ol>
							<li>Physical models capable of correctly capturing in 1D the complexity of 3D flow,
								thermal and combustion phenomena occurring in internal combustion engines</li>
							<li>An accurate discrete solver providing correct solutions for the resulting equations and flow network</li>
							<li>Proven processes for accurate calibration of physical models</li>
						</ol>
					</Trans>
					{image && (
						<p>
							<GatsbyImage image={image} alt="WAVE calibrated model" />
							<small>Calibrated model</small>
						</p>
					)}
					<Trans i18nKey="content_2">
						<ul>
							<li>Fast ICE solver delivering accurate flow and acoustics physics resolution</li>
							<li>Faster than real-time Quasi-Propagatory Model (QPM) solver for real-time applications</li>
							<li>Kinetics solvers for accurate representation of flame propagation and autoignition</li>
							<li>Custom accurate K-k cylinder turbulence model</li>
							<li>Advanced engine combustion models for premixed and non-premixed combustion including
								classical prescribed burn rate and predictive modelling</li>
							<li>Single and multiple-fuel operation with prescribed and predictive combustion</li>
							<li>Special models for lean hydrogen combustion</li>
							<li>State-of-the-art compressor and turbine physics</li>
							<li>Accurate solid heat-transfer modelling</li>
						</ul>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_OurCapabilities")}
						className="widget__sibling__pages">
						<AssetList>
							{WAVE_CAPABILITIES_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default ProductWaveSolversPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-wave-faspm", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/products/wave/Realis-Simulation_WAVE.png" }) {
		...imageBreaker
	}
	image: file(relativePath: { eq: "content/products/wave/Realis-Simulation_WAVE_CalibratedModel.png" }) {
		...imageBreaker
	}
}
`;

